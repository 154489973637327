
import { defineComponent } from 'vue';

import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'seip-claim-2-reassessment-approved-bill-list',
  data() {
    return {
      bill: {
        tranche_id: '',
        entity_id: '',
        entity_contract_id: '',
      } as any,
      tranches: [],
      entityInfos: [],
      contractInfos: [],
      allBillNo: [],
      billList: [],
      entity_short_name: '' as any,
      loading: false,
      load: false,
      showBillList: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getTranche();
    await this.getAssociation();
    this.bill.tranche_id = this.$route.params.tranche_id;
    this.bill.entity_id = this.$route.params.entity_id;
    this.entity_short_name = this.$route.params.entity_short_name;

    if (this.bill.tranche_id && this.bill.entity_id) {
      await this.getBillList();
      await this.getEntityContract();
    }
  },
  methods: {
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityContract() {
      this.load = true;
      await ApiService.get(
        'entity/contract/list?entity_info_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id
      )
        .then((response) => {
          this.contractInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getBillList() {
      this.load = true;
      await ApiService.get(
        'bill/claim_2/reassessment/seip_bill_list?entity_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id +
          '&entity_contract_id=' +
          this.bill.entity_contract_id +
          '&active_status=3'
      )
        .then((response) => {
          this.billList = response.data.data;
          this.showBillList = true;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async entityNameChange(id) {
      await ApiService.get('entity/show/' + id)
        .then((response) => {
          this.entity_short_name = response.data.data.entity_short_name;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {},
});
